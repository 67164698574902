import React, { Fragment } from 'react';
import ReactPlayer from "react-player";

class Tutorial extends React.Component {

	render() {
		return (
			<Fragment>
				<div className="container mb-3">
					<h3>1.Introducción</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=_LmvbRzJx8M"
					/>
				</div>
				<div className="container mb-3">
					<h3>2.Crear perfil</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=qklwQW4S8hk"
					/>
				</div>
				<div className="container mb-3">
					<h3>3.Crear una categoría</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=RHMxwF0Ke_Q"
					/>
				</div>
				<div className="container mb-3">
					<h3>4.Añadir foto de categoría</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=8vkYLrwMqlA"
					/>
				</div>
				<div className="container mb-3">
					<h3>5.Agregar un producto</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=LeNB1oqTSG4"
					/>
				</div>
				<div className="container mb-3">
					<h3>6.Ver comentarios o sugerencias</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=0G985T2iF9E"
					/>
				</div>
			</Fragment>
		);
	}
}

export default Tutorial;
